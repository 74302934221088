<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit">{{ $t('Links') }}</div>
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :showHeader="false"
                          :tableName="$t('Links')"
                          class="links-table"
                          :dataTableIsLoading="dataTableIsLoading"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :showButtons="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'name', title: 'Name',sortable: true},
              {name: 'favicon', title: '', width: '34px', sortable: false},
              {name: 'url', title: 'Url', width: '22%', sortable: true},
              {name: 'username', title: 'Username', sortable: true},
              {name: 'password', title: 'Password', sortable: true},
              {name: 'comment', title: 'Comment', sortable: true},
              // {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span v-if="item.name">{{ item.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <img v-if="item.url" :src="`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item.url}/&size=48` || ''" style="height: 20px;width: 20px; display: block" alt="">
              </td>
              <td>
                <div v-if="item.url" style="width: 20vw; max-width: 1160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  <a :href="item.url" target="_blank">{{ item.url }}</a>
                </div>
              </td>
              <td>
                <span v-if="item.username">{{ item.username }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.password">{{ item.password }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <div v-if="item.comment" style="width: 15vw; max-width: 700px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.comment }}</div>
                <i v-else class="ti-minus"></i>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import datatableMixins from "@/mixins/datatableMixins";
import formatMixins from "@/mixins/formatMixins";
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ datatableMixins, formatMixins, httpMixins ],
  // components: { Spinner, SearchInput },
	data() {
		return {
      fieldsExpand: '',
      apiLink: 'link/get-user-links',
      routePath: '/links',
      sort: {
        column: 'name',
        direction: '',
      },
		}
	},
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
	},
  methods: {
    async getItems() {
      this.dataTableIsLoading = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
    async deleteItem() {
      console.log('delete link')
      // try {
      //   const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
      //   if (status === 204) {
      //     this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750});
      //     this.updatedItemId = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   if (err) {
      //     this.$toast.add({severity:'error', summary: this.$t('Error'), detail: `${err.message}`, life: 1750})
      //     console.log(`${err.response?.data?.name}: ${err.response?.data?.message}`)
      //   }
      // }
    },
	},
  computed: {
    actionButtonsCount() {
      return 0
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">
.links-table {
  tr {
    td {
      padding: 7px;
      height: 48px;
    }
  }
}
</style>